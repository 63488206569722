<template>
  <div
    class="group-create-container"
    :style="{
      height: `${$screenHeight}px`,
    }"
  >
    <div class="form-title">
      <strong>{{ detail ? "编辑" : "新建" }}小组：</strong>
    </div>
    <van-form @submit="onSubmit" class="group-create-form">
      <div class="form__item" v-for="(item, index) in render" :key="index">
        <template v-if="item.type === 'img'">
          <template v-if="!detail || isAdmin">
            <van-field
              :label="item.label"
              class="custom-img-input"
              :rules="rules[item.name]"
              v-model="form[item.name]"
            >
              <template #input>
                <wx-upload
                  prefix="group-cover"
                  :limit="1"
                  @onChange="onUploadChange"
                  :outputImgs="uploadOutputImgs"
                ></wx-upload>
              </template>
            </van-field>
          </template>
          <template v-else>
            <p class="temp__label">小组头像</p>
            <div class="temp__img-box">
              <img
                class=""
                v-for="(src, index) in uploadOutputImgs"
                :key="index"
                :src="src | parseImage"
                alt=""
              />
            </div>
          </template>
        </template>
        <template v-else-if="item.type === 'text'">
          <van-field
            v-model="form[item.name]"
            :label="item.label"
            :rules="rules[item.name]"
            :readonly="detail && !isAdmin"
          ></van-field>
        </template>
        <template v-else-if="item.type === 'textarea'">
          <van-field
            class="textarea"
            v-model="form[item.name]"
            :label="item.label"
            type="textarea"
            :maxlength="item.maxlength"
            :show-word-limit="item.maxlength > 0"
            :rules="rules[item.name]"
            :readonly="detail && !isAdmin"
          ></van-field>
        </template>
      </div>
      <div class="bottom-bts-container">
        <van-button class="form-cancel-bt" native-type="button" @click="goBack">取消</van-button>
        <van-button
          v-if="!detail || isAdmin"
          class="form-submit-bt"
          :loading="requesting"
          loading-text="提交中..."
          :disabled="!(iCanEdit || isCreate)"
          >确定</van-button
        >
        <van-button v-else class="form-submit-bt red" native-type="button" @click="onFinishGroup"
          ><strong>退出</strong></van-button
        >
      </div>
    </van-form>
    <div v-if="!isCreate && isAdmin" class="bt-out" @click="onFinishGroup">
      <van-button>{{ isAuthor ? "解散" : "退出" }}</van-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import wxUpload from '../common/_upload-bt.vue';

export default {
  name: 'group-create',
  components: {
    wxUpload,
  },
  props: ['id'],
  data() {
    return {
      form: {
        avatar: '',
        title: '',
        summary: '',
      },
      rules: {
        avatar: [
          {
            required: true,
            message: '请上传小组头像',
          },
        ],
        title: [
          {
            required: true,
            message: '请填写小组名',
          },
        ],
        summary: [
          {
            required: true,
            message: '请填写详细内容',
          },
        ],
      },
      render: [
        {
          name: 'avatar',
          type: 'img',
          label: '小组头像',
        },
        {
          name: 'title',
          type: 'text',
          label: '小组名称',
        },
        {
          name: 'summary',
          type: 'textarea',
          label: '小组简介',
          maxlength: 500,
        },
      ],
      requesting: false,
      detail: null,
      uploadOutputImgs: null,
      isAdmin: false,
      dissolveErr: null,
    };
  },
  computed: {
    ...mapState(['user']),
    iCanEdit() {
      return this.isAuthor || this.isAdmin;
    },
    isAuthor() {
      if (this.user && this.detail && this.detail.author) {
        return Number(this.user.uid) === Number(this.detail.author.uid);
      }
      return false;
    },
    isCreate() {
      return this.id === undefined;
    },
  },
  created() {
    const that = this;
    if (that.id !== undefined) {
      that.getDetail().then((res) => {
        that.detail = res.data.info;
        that.isAdmin = res.data.isAdmin;
        // that.form.avatar = that.detail.cover;
        that.form.title = that.detail.title;
        that.form.summary = that.detail.summary;
        that.uploadOutputImgs = that.detail.cover instanceof Array ? that.detail.cover : [that.detail.cover];
      });
    } else {
      //
      that.form.avatar = process.env.NODE_ENV === 'development'
        ? 'group-cover/20210514/FvQORvywvXceiNbYQmV-9MO7tyeD'
        : '';
    }
  },
  methods: {
    onUploadChange(val) {
      this.form.avatar = val;
    },
    onSubmit() {
      const that = this;
      const postData = {
        cover: that.form.avatar,
        title: that.form.title,
        summary: that.form.summary,
      };
      const url = this.isCreate ? '/api/group/edit' : `/api/group/edit/${that.id}`;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .post(url, postData)
          .then((res) => {
            // 跳转到小组详情
            that.$router.replace({
              path: `/group/${res.data.id}`,
            });
          })
          .catch((e) => {
            this.$notify(e.message);
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    getDetail() {
      return this.$request.get(`api/group/${this.id}`);
    },
    onFinishGroup() {
      const that = this;
      const dialogTitle = that.isAuthor ? '确认解散小组？' : '确认删除并退出？';
      const dialogMessage = that.isAuthor ? '解散小组后什么都没有了' : '退出小组后不会显示相关信息';
      const beforeClose = that.isAuthor ? this.onDissolveGroup : this.onOutGroup;
      that.$dialog
        .confirm({
          title: dialogTitle,
          message: dialogMessage,
          beforeClose,
        })
        .then(() => {
          if (!that.dissolveErr) {
            that.goOut();
          } else {
            that.$notify({
              message: that.dissolveErr.message,
            });
          }
        })
        .catch(() => {
          console.log('取消');
        });
    },
    onDissolveGroup(action, done) {
      // 解散群
      const that = this;
      if (action === 'confirm') {
        that.$request
          .get(`api/group/dissolve/${this.id}`)
          .then((res) => {
            if (res.data === 'SUCCESS') {
              console.log(res);
              that.$notify({
                type: 'success',
                message: '操作成功',
              });
            }
          })
          .catch((e) => {
            that.dissolveErr = e;
          })
          .finally(() => {
            done();
          });
      } else {
        done();
      }
    },
    onOutGroup(action, done) {
      // 退出群
      const that = this;
      if (action === 'confirm') {
        that.$request
          .get(`api/group/out/${this.id}`)
          .then((res) => {
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            done();
          });
      } else {
        done();
      }
    },
    goOut() {
      this.$router.replace({
        path: '/groups',
      });
    },
  },
};
</script>

<style></style>
